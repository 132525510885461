<template>
  <div class="col">
    <!-- Start Design For Print -->
    <div class="the_main_container_for_print_all_pdf bg-white">
      <div class="main_container">
        <!-- // Start User Details Large Screens  -->
        <div style="width: 100% " class="my-4 main_use_ur_pattern_container_lg">
          <card class="">
            <div class="main_auth_user_info">
              <div>
                <span class="d-block mb-2 n_name">{{
                  `${user2.first_name} ${user2.last_name}`
                }}</span>
                <span class="d-block n_email"> {{ user2.email }}</span>
              </div>
              <div class="classification_classification">
                <span
                  class="classification"
                  v-if="user2.classification == 'Bridge'"
                  >{{ localization("user type bridge") }}</span
                >
                <span
                  class="classification"
                  v-if="user2.classification == 'Dynamic'"
                  >{{ localization("User type dynamic") }}</span
                >
                <span
                  class="classification"
                  v-if="user2.classification == 'Strong-Willed Learner'"
                  >{{ localization("user type SWL") }}</span
                >
              </div>
            </div>
            <div class="which_user_type mt-2 mb-3">
              <div class="nn">
                <div class="another_content">
                  <span
                    class="tit text-uppercase"
                    v-if="user2.classification == 'Bridge'"
                    >{{ localization("user type bridge") }}
                    {{ localization("definition") }}
                  </span>
                  <span
                    class="tit text-uppercase"
                    v-if="user2.classification == 'Dynamic'"
                    >{{ localization("User type dynamic") }}
                    {{ localization("definition") }}</span
                  >
                  <span
                    class="tit text-uppercase"
                    v-if="user2.classification == 'Strong-Willed Learner'"
                    >{{ localization("user type SWL") }}
                    {{ localization("definition") }}</span
                  >
                  <!-- Description Text  -->

                  <span
                    class="tex d-block"
                    v-if="user2.classification == 'Bridge'"
                  >
                    {{ localization("bridge tool tip") }}
                  </span>
                  <span
                    class="tex d-block"
                    v-if="user2.classification == 'Dynamic'"
                  >
                    {{ localization("dynamic tool tip") }}
                  </span>
                  <span
                    class="tex d-block"
                    v-if="user2.classification == 'Strong-Willed Learner'"
                  >
                    {{ localization("swl to tip") }}
                  </span>
                </div>
                <!-- Dynamic -->
                <!-- Strong-Willed Learner -->
              </div>
            </div>
            <div class="row my-4  main-card-print">
              <div
                class="col-sm-12 px-0 px-lg-3 d-flex align-items-center the_patterns_with_new_theme"
              >
                <!-- Start Sequence  -->
                <div style="min-width: 25%;">
                  <card
                    :height="`${userScore.sequence * 2.5 + 120}px`"
                    style="
                      background-color: #39c;
                      border-radius: 0;
                      width: 100%;
                      max-height: 230px;
                      min-height: 120px;
                    "
                    class="my-auto score"
                  >
                    <div class="d-flex flex-column my-auto">
                      <!-- score title text -->
                      <!-- Medium & larger screens text   -->
                      <p
                        class="mt-1 text-center text-white mb-0"
                        style="font-size: 2rem"
                      >
                        {{ localization("Sequence") }}
                      </p>
                      <div
                        class="
                        d-flex
                        align-items-center align-items-xxl-start
                        justify-content-center justify-content-lg-around
                      "
                      >
                        <!-- First Letter  -->
                        <p class="letter mb-0 text-white">
                          {{ localization("Sequence shortcut") }}
                        </p>
                        <!-- Score Number  -->

                        <!-- Large Screens  -->
                        <!-- Max value 35 - min value 7 = 28 & 25 is defferience between max and min font size & 50 is min font value -->
                        <p
                          class="number mb-0 text-white"
                          :style="{
                            fontSize: (userScore.sequence / 28) * 25 + 65 + 'px'
                          }"
                        >
                          {{
                            userScore.sequence != null ? userScore.sequence : 0
                          }}
                        </p>
                      </div>
                    </div>
                  </card>
                  <div class="new_theme_pattern_content">
                    <div class="main_content_container with_sequence">
                      <span class="d-block text-uppercase ti">{{
                        localization("SEQUENTIAL LEARNING PATTERNS")
                      }}</span>
                      <span class="d-block te">{{
                        localization("Organizes life")
                      }}</span>
                      <span class="d-block te">{{
                        localization("Makes Lists")
                      }}</span>
                      <span class="d-block te">{{
                        localization("Lives by schedules")
                      }}</span>
                      <span class="d-block te">{{
                        localization("Makes extensive plans")
                      }}</span>
                    </div>
                  </div>
                </div>
                <!-- End Sequence  -->

                <!-- Start Precision   -->
                <div style="min-width: 25%;">
                  <card
                    :height="`${userScore.precision * 2.5 + 120}px`"
                    style="
              border-radius: 0;

              min-width: 25%;
              background-color: #3c3;
              max-height: 230px;
              min-height: 120px;
            "
                    class="my-auto score"
                  >
                    <div class="d-flex flex-column my-auto">
                      <!-- score title text -->

                      <!-- Medium & larger screens text   -->
                      <p
                        class="mt-1 text-center text-white mb-0"
                        style="font-size: 2rem"
                      >
                        {{ localization("precision") }}
                      </p>

                      <div
                        class="
                  d-flex
                  align-items-center align-items-xxl-start
                  justify-content-center justify-content-lg-around
                "
                      >
                        <!-- First Letter  -->
                        <p class="letter mb-0 text-white">
                          {{ localization("precision shortcut") }}
                        </p>
                        <!-- Score Number  -->
                        <!-- Large Screens  -->
                        <!-- Max value 35 - min value 7 = 28 & 25 is defferience between max and min font size & 65 is min font value -->
                        <p
                          class="number mb-0 text-white"
                          :style="{
                            fontSize:
                              (userScore.precision / 28) * 25 + 65 + 'px'
                          }"
                        >
                          {{
                            userScore.precision != null
                              ? userScore.precision
                              : 0
                          }}
                        </p>
                      </div>
                    </div>
                  </card>
                  <div class="new_theme_pattern_content">
                    <div class="main_content_container with_precision">
                      <span class="d-block text-uppercase ti">{{
                        localization("PRECISION LEARNING PATTERNS")
                      }}</span>
                      <span class="d-block te">{{
                        localization("Relishes information")
                      }}</span>
                      <span class="d-block te">{{
                        localization("Seeks facts")
                      }}</span>
                      <span class="d-block te">{{
                        localization("Must be right")
                      }}</span>
                      <span class="d-block te">{{
                        localization("Provides detailed responses")
                      }}</span>
                    </div>
                  </div>
                </div>
                <!-- End Precision   -->
                <!-- Start Technical Reasoning  -->
                <div style="min-width: 25%;">
                  <!-- border: 1px solid #ccc; -->
                  <card
                    :height="`${userScore.technical_reasoning * 2.5 + 120}px`"
                    style="
                border-radius: 0;

                min-width: 25%;
                background-color: #f93;
                max-height: 230px;
                min-height: 120px;
              "
                    class="my-auto score"
                  >
                    <div class="d-flex flex-column my-auto">
                      <!-- score title text -->
                      <!-- Medium & larger screens text   -->
                      <p
                        class="mt-1 text-center text-white mb-0"
                        style="font-size: 1.6rem"
                      >
                        {{ localization("Technical Reasoning") }}
                      </p>
                      <div
                        class="
                    d-flex
                    align-items-center align-items-xxl-start
                    justify-content-center justify-content-lg-around

                  "
                      >
                        <!-- First Letter  -->
                        <p class="letter mb-0 text-white">
                          {{ localization("Technical Reasoning shortcut") }}
                        </p>
                        <!-- Score Number  -->
                        <!-- Large Screens  -->
                        <!-- Max value 35 - min value 7 = 28 & 25 is defferience between max and min font size & 65 is min font value -->
                        <p
                          class="number mb-0 text-white"
                          :style="{
                            fontSize:
                              (userScore.technical_reasoning / 28) * 25 +
                              65 +
                              'px'
                          }"
                        >
                          {{
                            userScore.technical_reasoning != null
                              ? userScore.technical_reasoning
                              : 0
                          }}
                        </p>
                      </div>
                    </div>
                  </card>
                  <div class="new_theme_pattern_content">
                    <div
                      class="main_content_container with_technical_reasoning"
                    >
                      <span class="d-block text-uppercase ti">{{
                        localization("TECHNICAL LEARNING PATTERNS")
                      }}</span>
                      <span class="d-block te">{{
                        localization("Solves problems")
                      }}</span>
                      <span class="d-block te">{{
                        localization("Uses few words")
                      }}</span>
                      <span class="d-block te">{{
                        localization("Looks for relevance and practicality")
                      }}</span>
                      <span class="d-block te">{{
                        localization("Works alone")
                      }}</span>
                    </div>
                  </div>
                </div>
                <!-- End Technical Reasoning  -->
                <!--Start Confluence  -->
                <div style="min-width: 25%;">
                  <card
                    :height="`${userScore.confluence * 2.5 + 120}px`"
                    style="
                    border-radius: 0;

                    min-width: 25%;
                    background-color: #c36;
                    max-height: 230px;
                    min-height: 120px;
                  "
                    class="my-auto score"
                  >
                    <div class="d-flex flex-column my-auto">
                      <!-- score title text -->
                      <!-- Medium & larger screens text   -->
                      <p
                        class="mt-1 text-center text-white mb-0"
                        style="font-size: 2rem"
                      >
                        {{ localization("Confluence") }}
                      </p>

                      <div
                        class="
                        d-flex
                        align-items-center align-items-xxl-start
                        justify-content-center justify-content-lg-around
                      "
                      >
                        <!-- First Letter  -->
                        <p class="letter mb-0 text-white">
                          {{ localization("Confluence shortcut") }}
                        </p>
                        <!-- Score Number  -->
                        <!-- Large Screens  -->
                        <!-- Max value 35 - min value 7 = 28 & 25 is defferience between max and min font size & 65 is min font value -->
                        <p
                          class="number mb-0 text-white"
                          :style="{
                            fontSize:
                              (userScore.confluence / 28) * 25 + 65 + 'px'
                          }"
                        >
                          {{
                            userScore.confluence != null
                              ? userScore.confluence
                              : 0
                          }}
                        </p>
                      </div>
                    </div>
                  </card>
                  <div class="new_theme_pattern_content">
                    <div class="main_content_container with_confluence">
                      <span class="d-block text-uppercase ti">{{
                        localization("CONFLUENCE LEARNING PATTERNS")
                      }}</span>
                      <span class="d-block te">{{
                        localization("Takes risks")
                      }}</span>
                      <span class="d-block te">{{
                        localization("Sees the big picture")
                      }}</span>
                      <span class="d-block te">{{
                        localization("Embraces innovation")
                      }}</span>
                      <span class="d-block te">{{
                        localization("Initiates change")
                      }}</span>
                    </div>
                  </div>
                </div>
                <!--End Confluence  -->
              </div>

              <!-- Start New Section  -->
              <div class="wrapper_for_use_first col-sm-12 mt-5 px-0 px-lg-3">
                <div class="main_co">
                  <div class="a_one with_title">
                    <span class="text-uppercase">{{
                      localization("DEGREE OF USE")
                    }}</span>
                    <span>:</span>
                  </div>
                  <div class="a_one ">
                    <span class="text-capitalize">{{
                      localization("Use First")
                    }}</span>
                    <span>:</span>
                    <span class="text-uppercase">35 - 25</span>
                  </div>
                  <div class="a_one a_two">
                    <span class="text-capitalize">{{
                      localization("As Needed")
                    }}</span>
                    <span>:</span>
                    <span class="text-uppercase">24 - 18</span>
                  </div>
                  <div class="a_one ">
                    <span class="text-capitalize">{{
                      localization("Avoid")
                    }}</span>
                    <span>:</span>
                    <span class="text-uppercase">17 - 7</span>
                  </div>
                </div>
              </div>
            </div>
          </card>
        </div>
        <!-- // End User Details Large Screens  -->
        <!-- Start Two Strategy Cards   -->
        <div class="mt-4 mb-4 main_strategy_container">
          <div
            class="cms_strategy_wrapper"
            v-if="userStrategyBoxes[0].body != null"
          >
            <div v-html="userStrategyBoxes[0].body"></div>
          </div>
          <div
            class="cms_strategy_wrapper"
            v-if="userStrategyBoxes[1].body != null"
          >
            <div v-html="userStrategyBoxes[1].body"></div>
          </div>
        </div>
        <!-- End Two Strategy Cards -->
        <!-- Start Every Task -->
        <div class="every_task_with_us">
          <div
            style="width: 100%"
            class="print-page-break looping_tasks_here"
            v-for="task in getUserCareerTasks"
            :key="task.id"
          >
            <card>
              <template #header>
                <div
                  class="
                  head_of_tasks
                "
                >
                  <h5 class="mb-2 the_task_name fs-3">
                    <i class="fa fa-tasks mr-2"></i>
                    <span>{{ task.name }}</span>

                    <span class="text-muted">( {{ task.topic_name }} )</span>
                  </h5>
                </div>
                <p
                  class="pl-2 str_tsk_desc"
                  style="
                  background: #aaaaaa;
                  padding: 5px;
                  color: #fff;
                  border-radius: 5px;
                "
                  :class="{ 'text-right': isRtl() }"
                >
                  {{ localization("Personal strategy description") }}
                </p>
                <div
                  class="mt-2 for-task-description"
                  style="line-height: 2.1"
                  v-html="task.description"
                ></div>
              </template>
              <!-- Start Collapse -->
              <!-- Start Collapse -->
              <el-collapse class="mt-2" v-model="panel" multiple>
                <div
                  class="child"
                  v-for="(item, i) in task.strategy_id"
                  :key="i"
                >
                  <el-collapse-item
                    :name="`${i}`"
                    multiple
                    :class="{ 'print-page-break': i == 'Precision' }"
                  >
                    <!-- //! start  collapse title -->
                    <template #title>
                      <div
                        class="main_collapse_title_wrapper "
                        style="width: 100%"
                        :class="{ is_rtl: isRtl() }"
                      >
                        <!-- mb-1 mt-2 isRtl() -->
                        <div>
                          <h4 class="every_collapse_title">
                            <i
                              :class="`fa fa-star mr-2 ${i}`"
                              :style="`color: ${getProgressColor(i)}`"
                            ></i>

                            {{ localization(i) }}
                          </h4>
                        </div>
                        <span class="">
                          <button
                            class="btn btn-social btn-round every_collapse_button"
                            :style="
                              `border-color:${getProgressColor(
                                i
                              )};  background: ${getProgressColor(i)}`
                            "
                          >
                            {{ getProgressValue(i) }}
                          </button>
                          <a
                            v-tooltip.top-center="
                              `${localization('Add strategy')}`
                            "
                            class="btn btn-default btn-link btn-md"
                          >
                            <i
                              class="fa fa-plus-circle fa-lg mb-4 mt-4"
                              :style="`color: ${getProgressColor(i)}`"
                            ></i>
                          </a>
                        </span>
                      </div>
                    </template>
                    <!-- //! end  collapse title -->
                    <div class="p-2 set_bg_white_for_share">
                      <l-alert
                        class="mt-2 alert_for_collapse"
                        :style="
                          `opacity:0.8;background-color : ${getProgressColor(
                            i
                          )}`
                        "
                        :closable="false"
                        show-icon
                        :class="{ 'text-right': isRtl() }"
                      >
                        {{
                          getHint(i, item.length > 0, getProgressValue(i), item)
                        }}
                      </l-alert>

                      <div
                        style="width: 100%; display: block"
                        v-if="item.length > 0"
                      >
                        <!-- Unlimited strategies  -->
                        <!-- New Code v-model="strategies"  -->
                        <el-checkbox-group
                          :value="
                            task.selected_strategy_id != null
                              ? task.selected_strategy_id
                              : []
                          "
                          class="mt-2"
                          :text-color="getProgressColor(i)"
                        >
                          <div
                            class="wrapper_check_speech"
                            v-for="(feedback, i) in item"
                            :key="i"
                          >
                            <el-checkbox
                              :label="feedback.id"
                              :value="feedback.id"
                              class="another-check-label"
                              >{{ feedback.level1 }}</el-checkbox
                            >

                            <i
                              v-tooltip.top-center="
                                `${localization('Read out loud')}`
                              "
                              class="fa fa-volume-up icons_style speech"
                              style="cursor: pointer"
                            ></i>
                          </div>
                        </el-checkbox-group>
                      </div>
                    </div>
                  </el-collapse-item>
                </div>
              </el-collapse>
              <!-- End Collapse -->
            </card>
          </div>
        </div>
        <!-- End Every Task -->
      </div>
    </div>
    <!-- End Design For Print -->
    <div>
      <card class="hide_me_printable">
        <server-table
          class="hidden-sm-only hidden-xs-only"
          :count="$store.getters['user/tasks_count']"
          :DataItems="$store.getters['user/tasks']"
          @Refresh="Refresh"
          @reset="filter = {}"
          :filter="filter"
          :title="'Tasks'"
          hidden_export_import
          admin_permission="admin_tasks"
          edit_permission="add_tasks"
          @selection="selections"
        >
          <!-- search & filter fields  -->
          <template #filter>
            <!-- search by topic  -->
            <el-select
              v-model="filter.topic_id"
              clearable
              :placeholder="`${localization('Topic')}`"
              class="mt-2 select-default w-100"
            >
              <el-option
                v-for="(option, index) in $store.getters['topics/topics']"
                :key="index"
                :label="option.name"
                :value="option.id"
              ></el-option>
            </el-select>
          </template>
          <!-- Tasks Table  -->
          <template #columns>
            <!-- Select Users -->
            <el-table-column type="selection" width="50"></el-table-column>
            <!-- Task Name  -->
            <el-table-column
              prop="name"
              sortable
              :label="`${localization('Name')}`"
            ></el-table-column>
            <!-- Task Topic -->
            <el-table-column
              prop="topic_name"
              sortable
              :label="`${localization('Topic')}`"
              class="d-none d-md-block"
            ></el-table-column>
            <!-- Task Actions  -->
            <el-table-column prop="" label="_">
              <template slot-scope="scope">
                <div class="td_actions flex justify-content-end">
                  <!-- Edit Task Button  -->
                  <a
                    v-if="hasPermission('edit_tasks')"
                    v-tooltip.top-center="
                      `${localization('Edit personal strategy')}`
                    "
                    class="btn btn-warning btn-simple btn-link"
                  >
                    <i class="fa fa-edit"></i
                  ></a>
                  <!-- show task button  -->
                  <router-link
                    v-if="hasPermission('show_tasks')"
                    :to="`/edit-task/${scope.row.id}`"
                    v-tooltip.top-center="
                      `${localization('Show personal strategy')}`
                    "
                    class="btn btn-info btn-link btn-xs"
                  >
                    <i class="fa fa-eye"></i
                  ></router-link>
                  <!-- Delete Task Button  -->
                  <a
                    v-if="hasPermission('edit_tasks')"
                    v-tooltip.top-center="
                      `${localization('Delete personal strategy')}`
                    "
                    class="btn btn-danger btn-simple btn-link"
                  >
                    <i class="fa fa-times"></i
                  ></a>
                </div>
              </template>
            </el-table-column>
          </template>
          <!-- Add Task Button  -->
          <template #action>
            <button
              class="border-0 text-dark d-block "
              style="background: transparent"
              :disabled="selected.length == 0"
              @click="printTasks"
            >
              <el-icon class="el-icon-print"></el-icon>
              {{ localization("Print Tasks") }}
            </button>
          </template>
        </server-table>
      </card>
    </div>
  </div>
</template>

<script>
import LAlert from "src/components/Alert";
import ServerTable from "@/components/DataTablePagination.vue";
import { Card, Table as LTable } from "src/components/index";
export default {
  data() {
    return {
      score: {},
      user_invalid_score: false,
      filter: {},
      selected: [],
      selected_panel: [],
      panel: [`Precision`, `Sequence`, "Technical Reasoning", "Confluence"],
      strategies: []
    };
  },
  components: {
    Card,
    LTable,
    ServerTable,
    LAlert
  },
  mounted() {
    this.Refresh();
  },
  computed: {
    user2: function() {
      return this.$store.state.user.user;
    },
    image: function() {
      if (this.user.image) {
        return `${process.env.VUE_APP_BASE_URL}/users/photo/${this.user.image}`;
      }
      return "/static/img/lml-swerl.jpg";
    },
    userScore() {
      return this.$store.getters["user/score"];
    },
    tasks() {
      return this.$store.getters["user/tasks"];
    },
    userStrategyBoxes() {
      return this.$store.getters["user/get_user_strategy_boxes"];
    },
    // All Tasks
    getUserCareerTasks() {
      return this.$store.getters["user/getUserSchoolTasks"];
    }
  },
  methods: {
    // Refresh() {
    //   this.$store.dispatch("user/user", this.$route.params.id).then(res => {
    //     console.log(res);
    //     if (this.user.selected_attributes != null) {
    //       this.selected = this.user.selected_attributes;
    //     }
    //   });
    // },
    Refresh(query) {
      this.$store
        .dispatch("user/userTask", { id: this.$route.params.id, query: query })
        .then(() => {
          this.$store.dispatch("topics/GetTopics", { query: null });
        });
      this.$store.dispatch("user/handlerUserStrategyBoxes");
      this.$store.dispatch("topics/handlerStandardTopics");
    },
    getProgressColor(key) {
      switch (key) {
        case "Precision":
          return "#3c3";
        case "Technical Reasoning":
          return "#f93";
        case "Confluence":
          return "#c36";
        case "Sequence":
          return "#39c";
      }
    },
    selections(selections) {
      this.selected = Array.from(selections, el => el.id);
      console.log(this.selected);
    },
    // getUserCareerTasks
    printTasks(query) {
      let payload = {
        tasks: this.selected
      };
      this.$store.dispatch("user/handlePrintSchoolTasks", payload).then(() => {
        window.print();
        this.Refresh();
        window.location.reload();
      });
    },
    getHint(key, decode, score, item) {
      var type = false;

      if (score >= 7 && score < 18) {
        type = "Forge";
      } else if (score >= 18 && score < 25) {
        type = "Intensify";
      }
      if (decode) {
        type = item[0] ? item[0].classification : type;
        if (type == "Use First") {
          if (key == "Sequence") {
            return `${this.localization("Sequence - Use First")}`;
          } else if (key == "Precision") {
            return `${this.localization("Precision - Use First")}`;
          } else if (key == "Technical Reasoning") {
            return `${this.localization("TR - Use First")}`;
          } else if (key == "Confluence") {
            return `${this.localization("Confluence - Use First")}`;
          }
        } else if (type) {
          // console.log(score);
          console.log(type);
          // Intensify
          if (type == "Intensify" && key == "Sequence") {
            return `${this.localization("Sequence Intensify")}`;
          } else if (type == "Intensify" && key == "Precision") {
            return `${this.localization("Precision Intensify")}`;
          } else if (type == "Intensify" && key == "Technical Reasoning") {
            return `${this.localization("TR Intensify")}`;
          } else if (type == "Intensify" && key == "Confluence") {
            return `${this.localization("Confluence Intensify")}`;
          }
          if (type == "Forge" && key == "Sequence") {
            return `${this.localization("Sequence Forge")}`;
          } else if (type == "Forge" && key == "Precision") {
            return `${this.localization("Precision Forge")}`;
          } else if (type == "Forge" && key == "Technical Reasoning") {
            return `${this.localization("TR Forge")}`;
          } else if (type == "Forge" && key == "Confluence") {
            return `${this.localization("Confluence Forge")}`;
          }
        } else {
          //// if pattern exist and score between 25 &b 35
          if (key == "Sequence") {
            return `${this.localization("Sequence - Use First")}`;
          } else if (key == "Precision") {
            return `${this.localization("Precision - Use First")}`;
          } else if (key == "Technical Reasoning") {
            return `${this.localization("TR - Use First")}`;
          } else if (key == "Confluence") {
            return `${this.localization("Confluence - Use First")}`;
          }
        }
      } else {
        // If no pattern //// and score between 7-18 -- type now FORGE
        if (type && type == "Forge") {
          if (key == "Sequence") {
            return `${this.localization("Sequence No Term")}`;
          } else if (key == "Precision") {
            return `${this.localization("Precision No Term")}`;
          } else if (key == "Technical Reasoning") {
            return `${this.localization("TR No Term")}`;
          } else if (key == "Confluence") {
            return `${this.localization("Confluence No Term")}`;
          }
        } else if (type && type == "Intensify") {
          if (key == "Sequence") {
            return `${this.localization("Sequence Intensify")}`;
          } else if (key == "Precision") {
            return `${this.localization("Precision Intensify")}`;
          } else if (key == "Technical Reasoning") {
            return `${this.localization("TR Intensify")}`;
          } else if (key == "Confluence") {
            return `${this.localization("Confluence Intensify")}`;
          }
        } else {
          if (key == "Sequence") {
            return `${this.localization("Sequence tether")}`;
          } else if (key == "Precision") {
            return `${this.localization("Precision tether")}`;
          } else if (key == "Technical Reasoning") {
            return `${this.localization("TR tether")}`;
          } else if (key == "Confluence") {
            return `${this.localization("Confluence tether")}`;
          }
        }
      }
    },
    getProgressColor(key) {
      switch (key) {
        case "Precision":
          return "#3c3";
        case "Technical Reasoning":
          return "#f93";
        case "Confluence":
          return "#c36";
        case "Sequence":
          return "#39c";
      }
    },
    getProgressValue(key) {
      switch (key) {
        case "Precision":
          return this.userScore.precision;
        case "Technical Reasoning":
          return this.userScore.technical_reasoning;
        case "Confluence":
          return this.userScore.confluence;
        case "Sequence":
          return this.userScore.sequence;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .precision {
  display: inline-block;
  color: #3c3;
  // text-decoration: underline;
  margin: 0;
  padding: 0 0.3rem;
  font-weight: bold;
  position: relative;
  &::before {
    position: absolute;
    content: "";
    left: 0;
    width: 100%;
    height: 1.5px;
    top: 100%;
    background-color: #3c3;
  }
}

::v-deep .technical_reasoning {
  display: inline-block;
  color: #f93;
  margin: 0;
  // padding: 3px;
  padding: 0 0.3rem;
  font-weight: bold;
  position: relative;
  &::before {
    position: absolute;
    content: "";
    left: 0;
    width: 100%;
    height: 1.5px;
    top: 100%;
    background-color: #f93;
  }
}

::v-deep .sequence {
  display: inline-block;
  color: #39c;
  margin: 0;
  // padding: 3px;
  padding: 0 0.3rem;
  font-weight: bold;
  position: relative;
  transition: 0.5s;
  &::before {
    position: absolute;
    content: "";
    left: 0;
    width: 100%;
    height: 1.5px;
    top: 100%;
    background-color: #39c;
  }
}

::v-deep .confluence {
  display: inline-block;
  color: #c36;
  margin: 0;
  // padding: 3px;
  padding: 0 0.3rem;
  font-weight: bold;
  position: relative;
  &::before {
    position: absolute;
    content: "";
    left: 0;
    width: 100%;
    height: 1.5px;
    top: 100%;
    background-color: #c36;
  }
}
.label {
  border-radius: 50%;
  margin: 0.5rem;
  width: 3rem;
  height: 3rem;
  text-align: center;
  font-size: 1rem;
  line-height: 2.8;
  color: white;
}
.letter {
  font-size: 40px;
}
// Start the_main_container_for_print_all_pdf
.the_main_container_for_print_all_pdf {
  display: none;
  @media print {
    display: block !important;
  }
  .main_container {
    .main_auth_user_info {
      text-align: center;
      display: block;
      // display: none;
      @media print {
        display: block !important;
      }
      .n_name {
        font-size: 24px;
        font-weight: bold;
        text-transform: capitalize;
      }
      .n_email {
        font-size: 22px;
      }
      .classification_classification {
        padding: 0 36px;
        min-width: 180px;
        border-radius: 12px;
        background-color: #00563f;
        height: 45px;
        display: flex;
        align-items: center;
        width: fit-content;
        color: #fff;
        margin: 10px auto;
      }
      // Below Score Cards
    }
    .the_patterns_with_new_theme {
      position: relative;
      .new_theme_pattern_content {
        // display: none;
        display: block;
        @media print {
          display: block !important;
        }
        padding: 0 10px;
        .main_content_container {
          border-left: 2px solid;
          border-right: 2px solid;
          border-bottom: 2px solid;
          text-align: center;
          padding: 10px 5px;
          &.with_sequence {
            border-color: #39c;
          }
          &.with_precision {
            border-color: #3c3;
          }
          &.with_technical_reasoning {
            border-color: #f93;
          }
          &.with_confluence {
            border-color: #c36;
          }
          .ti {
            // color: #555;
            font-size: 15px;
          }
          .te {
            color: #777;
            font-size: 13px;
          }
        }
      }
    }
    .wrapper_for_use_first {
      display: block;
      // display: none;
      @media print {
        display: block !important;
      }
      .main_co {
        background-color: #eee;
        display: flex;
        justify-content: center;
        gap: 10px;
        color: #7d7979;
        padding: 15px;
        .a_one {
          &.with_title {
            color: #555;
          }
        }
        .a_two {
          border-left: 3px solid #777;
          border-right: 3px solid #777;
          padding-left: 10px;
          padding-right: 10px;
        }
      }
    }
    .main_collapse_title_wrapper {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      .every_collapse_title {
        @media (min-width: 320px) and (max-width: 480px) {
          font-size: 18px;
        }
        @media (min-width: 320px) and (max-width: 350px) {
          font-size: 11px;
        }
      }
      .every_collapse_button {
        color: #fff;
        width: 50px;
        height: 50px;
        @media (min-width: 320px) and (max-width: 480px) {
          width: 36px;
          height: 36px;
        }
      }
      &.is_rtl {
        flex-direction: row-reverse;
        padding-right: 10px;
      }
    }
    .main_strategy_container {
      display: flex;
      gap: 4%;
      page-break-inside: avoid;
      @media (min-width: 320px) and (max-width: 991px) {
        flex-wrap: wrap;
      }
      @media print {
        flex-wrap: nowrap;
        margin-top: 40px !important;
        page-break-after: always;
      }
      .cms_strategy_wrapper {
        width: calc(96% / 2);
        padding: 15px;
        color: #fff;
        border-radius: 10px;
        background-color: #00563f;
        min-height: 170px;
        @media (min-width: 320px) and (max-width: 991px) {
          margin-bottom: 20px;
        }
      }
    }
    .set_bg_white_for_share {
      background: #fff;
    }
    .alert_for_collapse {
      font-size: 1.2rem;
      @media (min-width: 320px) and (max-width: 767px) {
        font-size: 1rem;
      }
      /* Define custom class for page break */
      .print-page-break {
        page-break-inside: avoid;
      }
    }
    .el-collapse {
      background: #fff;
    }
    .wrapper_check_speech {
      display: flex;
      justify-content: space-between;
      margin-bottom: 5px;
    }
    .icons_style {
      background-color: #eee;
      // box-shadow: 0 0 6px 0 rgba(#000, 0.2), 0 0 5px -2px rgba(#000, 0.1);
      font-size: 14px;
      width: 30px;
      height: 30px;
      // box-shadow: $simple-shadow;
      text-align: center;
      line-height: 30px;
      border-radius: 50%;
      cursor: pointer;
      color: #777;
      transition: 0.3s;
      &.speech {
        &:hover {
          background-color: #11bfe3;
          color: #fff;
        }
      }
      &.delete {
        &:hover {
          background-color: #fb404b;
          color: #fff;
        }
      }
    }
  }
}
::v-deep .el-checkbox {
  text-transform: capitalize !important;
  display: flex;
  &.check_box_with_rtl {
    flex-direction: row-reverse;
    margin-right: 0;
    align-items: center;
    gap: 3px;
  }
}

::v-deep .el-checkbox__label {
  word-break: break-word !important;
  white-space: pre-wrap;
  // word-wrap: break-word;
  white-space: normal;
  color: #000;
  padding: 0 7px;
  text-transform: initial;
}
::v-deep .el-checkbox__inner {
  border: 1px solid #000;
}
::v-deep .texst {
  word-break: break-word !important;
  word-wrap: break-word;
  white-space: normal;
  // white-space: pre-wrap;
  text-transform: initial;
  font-size: 1.2rem;
  line-height: 1;
  @media (min-width: 320px) and (max-width: 767px) {
    font-size: 1rem;
  }
}
.v-expansion-panel--active > button {
  background-color: #757575 !important;
  color: #fff;
}

.v-expansion-panel--active > .theme--light .v-icon {
  color: #fff !important;
}

.child.v-expansion-panel--active > button {
  background-color: #26a1da !important;
  color: #fff;
}

.v-slider--horizontal .v-slider__track-container {
  height: 10px !important;
}
::v-deep .el-card__body {
  display: none;
}
@media print {
  * {
    -webkit-print-color-adjust: exact !important; /* Chrome, Safari, Edge */
    color-adjust: exact !important; /*Firefox*/
  }
  .hide_me_printable {
    display: none !important;
  }
  .looping_tasks_here {
    page-break-inside: avoid;
  }
  .print-page-break {
    page-break-after: always;
  }
}
/* Add print styles to control page breaks */
@media print {
  .print-page-break {
    page-break-after: always;
  }
}
</style>
